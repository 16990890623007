.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 13px 0;
  transition: .3s background-color ease-in-out;

  @media (max-width: 968px) {
    background-color: var(--white-color);
  }

  &__container {
    @media (max-width: 968px) {
      padding: 9px 15px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 968px) {
      height: 30px;
    }
  }

  &.scrolled {
    background-color: var(--white-color);
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 968px) {
      display: none;
    }
  }

  &__nav {
    @media (max-width: 968px) {
      display: none;
    }
  }

  &__phone {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: var(--white-color);

    @media (max-width: 968px) {
      color: #64714F;
    }
  }

  &__logo {
    svg {
      fill: var(--white-color);

      @media (max-width: 968px) {
        fill: #64714f;
        display: flex;
      }
    }
  }
}

.header.scrolled .nav__link {
  color: #64714f;
}

.header.scrolled .nav__item::after {
  background-color: #64714f;
}

.header.scrolled .header__phone {
  color: #64714f;
}

.header.scrolled .header__logo svg {
  fill: #64714f;
}
