.tabs {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;

  @media (max-width: 768px) {
    gap: 20px;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
    }
  }

  &__item {
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__item:not(:last-child) {
    margin-right: 10px;
  }

  &__btn {
    border: none;
    cursor: pointer;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 132px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: #9b9b9a;
    background: #2c2c2c;
    border-radius: 8px;
    transition: color .3s ease-in-out;

    @media (max-width: 500px) {
      width: 100%;
    }

    &:hover {
      color: var(--white-color);
    }
  }

  &__btn--active {
    color: var(--white-color);
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    pointer-events: none;
  }

  &__content {
    display: none;
  }

  &__content--active {
    display: block;
  }
}

.content {

  &__block {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-bottom: 40px;

    @media (max-width: 968px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 30px;
    }

    @media (max-width: 768px) {
      margin-bottom: 25px;
    }

    @media (max-width: 500px) {
      margin-bottom: 20px;
    }
  }

  &__title {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 54px;
    line-height: 104%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.96);

    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 32px;
    }

    @media (max-width: 425px) {
      font-size: 28px;
    }

    @media (max-width: 375px) {
      font-size: 26px;
    }
  }

  &__descr {
    max-width: 285px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    color: rgba(255, 255, 255, 0.96);

    @media (max-width: 968px) {
      max-width: 100%;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 9px;
    justify-content: center;

    @media (max-width: 500px) {
      gap: 20px;
    }
  }

  &__wrapper {
    padding: 20px 20px 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 11px;
    position: relative;
  }

  &__card {
    width: 273px;
    height: 283px;
    border-radius: 10px;
    backdrop-filter: blur(5.5px);
    background: rgba(#fff, 0.15);

    @media (max-width: 500px) {
      width: 100%;
      height: auto;
    }
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: var(--white-color);
  }

  &__subname {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 15px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: rgba(#fff, 0.4);
    max-height: 185px;
    overflow-y: scroll;
    padding-right: 6px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 1000px;
      background: var(--white-color);
      transition: .7s background;
    }

    @media (max-width: 375px) {
      font-size: 14px;
    }
  }

  &__pic {
    max-width: 273px;
    height: 283px;

    @media (max-width: 500px) {
      width: 100%;
      max-width: 100%;
      height: 260px;
    }

    &--img {
      height: 100%;
      display: block;

      img {
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
