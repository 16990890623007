html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: #424242;
  color: var(--black-color);

  &::-webkit-scrollbar {
    width: 8px;
    background: #bdbdbd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #64714F;
    transition: .7s background;
  }
}

a {
  color: #64714F;
}

.swal2-styled.swal2-confirm {
  background-color: #64714f !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(100, 113, 79, .5) !important;
}

.header__container {
  padding: 15px;
}

.no-scroll {
  overflow: hidden;
}

.section {

  &__title {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 54px;
    line-height: 104%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 40px;
      margin-bottom: 25px;
    }

    @media (max-width: 500px) {
      font-size: 32px;
      margin-bottom: 20px;
    }

    @media (max-width: 425px) {
      font-size: 28px;
    }

    @media (max-width: 375px) {
      font-size: 26px;
    }

    &--dark {
      color: #2c2c2c;
    }

    &--white {
      color: var(--white-color);
    }

    &--whites {
      color: var(--white-color);
      margin-bottom: 20px;
    }
  }

  &__span {
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 54px;
    line-height: 104%;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 32px;
    }

    @media (max-width: 425px) {
      font-size: 28px;
    }

    @media (max-width: 375px) {
      font-size: 26px;
    }

    &--accent {
      color: #64714f;
    }

    &--silver {
      color: #898989;
    }
  }

  &__line {
    width: 100%;
    height: 2px;
    background-color: rgba(#fff, 0.2);
  }

  &__descr {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 14px;
    line-height: 123%;
    color: rgba(255, 255, 255, 0.96);
    margin-bottom: 40px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
}

.fslightbox-source {
  border-radius: 10px;
}

.fslightbox-toolbar {
  border-radius: 10px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
