.reviews {
  padding: 30px 0;
  border-radius: 0 0 15px 15px;
  background: #fff;

  &__block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
}
