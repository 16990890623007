.btn {

  &--call {
    border-radius: 4px;
    background: #64714f;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-transform: uppercase;
    text-align: center;
    color: rgba(255, 255, 255, 0.96);
    padding: 7px 27px;
    transition: .3s background ease-in-out;

    &:hover {
      background: #424b34;
    }
  }

  &--form {
    background: #64714f;
    border-radius: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: #fff;
    padding: 12px;
    width: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s background ease-in-out;

    &:hover {
      background: #424b34;
    }
  }

  &--price {
    background: #64714f;
    border-radius: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: var(--white-color);
    padding: 12px 0;
    width: 273px;
    transition: .3s background ease-in-out;

    @media (max-width: 500px) {
      width: 100%;
    }

    &:hover {
      background: #424b34;
    }
  }


  &--more {
    margin-top: 40px;
    width: 100%;
    border-radius: 8px;
    background: #64714f;
    padding: 12px 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: var(--white-color);
    text-transform: uppercase;
    transition: .3s background ease-in-out;

    &:hover {
      background: #424b34;
    }
  }

  &--submit {
    border-radius: 8px;
    background: #64714f;
    width: 273px;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: var(--white-color);
    transition: .3s background ease-in-out;

    @media (max-width: 1150px) {
      width: 100%;
    }

    &:hover {
      background: #424b34;
    }
  }

  &--view {
    border-radius: 10px;
    width: 272px;
    height: 341px;
    background: #64714f;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: center;
    color: var(--white-color);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    -webkit-transition: 0.3s background ease-in-out;
    transition: 0.3s background ease-in-out;

    @media (max-width: 768px) {
      & {
        width: 100%;
        height: 250px;
      }
    }

    @media (max-width: 375px) {
      & {
        width: 100%;
        height: 220px;
      }
    }

    &:hover {
      background: #424b34;
    }
  }

  &--link {
    background: #898989;
    border-radius: 10px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    width: 251px;
    height: 477px;
    -webkit-transition: 0.3s background ease-in-out;
    transition: 0.3s background ease-in-out;

    &:hover {
      background: #676767;
    }

    @media (max-width: 500px) {
      & {
        width: 100%;
        margin-left: 0;
        height: 50px;
      }
    }
  }

  &--views {
    background: #64714f;
    border-radius: 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 118%;
    text-align: center;
    color: #fff;
    padding: 12px 0;
    width: 100%;
    -webkit-transition: 0.3s background ease-in-out;
    transition: 0.3s background ease-in-out;

    &:hover {
      background: #424b34;
    }
  }

  &--view2 {
    border-radius: 10px;
    width: 272px;
    height: 341px;
    background: var(--white-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    text-align: center;
    color: #2c2c2c;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    vertical-align: middle;
    -webkit-transition: 0.3s background ease-in-out;
    transition: 0.3s background ease-in-out;

    &:hover {
      background: #e2e2e2;
    }

    @media (max-width: 768px) {
      & {
        width: 100%;
        height: 250px;
      }
    }

    @media (max-width: 375px) {
      & {
        width: 100%;
        height: 220px;
      }
    }
  }
}
