.contact-form {
  width: 560px;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__message {
    margin-bottom: 7px;
  }

  &__input {
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: var(--white-color);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: #2c2c2c;
    padding: 17px 20px;

    @media (max-width: 500px) {
      padding: 14px 20px;
      font-size: 14px;
    }

    &-wrapper {
      margin-bottom: 10px;

      &_phone {
        position: relative;
      }
    }

    &::placeholder {
      color: rgba(#2c2c2c, 0.4);
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
    }

    &_phone {
      padding-left: 50px;
    }
  }

  &__region {
    position: absolute;
    left: 12px;
    top: 24.5%;
  }

  &__policy {
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 20px;
    margin-left: 6px;
  }

  &__link {
    color: rgba(#fff, 0.4);
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox+label {
  display: flex;
  gap: 14px;
  align-items: center;
  user-select: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 169%;
  letter-spacing: -0.02em;
  color: rgba(#fff, 0.4);

  @media (max-width: 768px) {
    font-size: 13px;
  }
}

.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #fff;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked+label::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_178_475)'%3e%3cpath d='M7.8589 0.591231L4.24616 4.20363L2.14093 2.0984C1.87659 1.83406 1.44776 1.83406 1.18376 2.0984C0.919423 2.36274 0.919423 2.79123 1.18376 3.05557L3.76758 5.63939C3.89991 5.77172 4.07287 5.83772 4.24616 5.83772C4.41945 5.83772 4.59241 5.77172 4.72475 5.63939L8.81607 1.5484C9.08041 1.28406 9.08041 0.855569 8.81607 0.591231C8.55173 0.326892 8.1229 0.326892 7.8589 0.591231ZM4.99992 -7.37683C-0.598916 -7.37683 -5.15393 -2.82182 -5.15393 2.77702C-5.15393 8.37585 -0.598916 12.9309 4.99992 12.9309C10.5987 12.9309 15.1538 8.37585 15.1538 2.77702C15.1538 -2.82182 10.5987 -7.37683 4.99992 -7.37683ZM4.99992 11.577C0.14773 11.577 -3.80008 7.62954 -3.80008 2.77702C-3.80008 -2.07551 0.14773 -6.02298 4.99992 -6.02298C9.8521 -6.02298 13.7999 -2.07551 13.7999 2.77702C13.7999 7.62954 9.8521 11.577 4.99992 11.577Z' fill='white' /%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_178_475'%3e%3crect width='8.8' height='6.09231' fill='white' transform='translate(0.599976 0.0693359)' /%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
}
