@charset "UTF-8";
:root {
  --font-family: "Manrope", sans-serif;
  --second-family: "Roboto", sans-serif;
  --third-family: "SF Pro Display", sans-serif;
  --content-width: 1125px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --white-color: #fff;
  --black-color: #000;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/../fonts/Manrope-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/../fonts/SFProDisplay-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: #424242;
  color: var(--black-color);
}
.page__body::-webkit-scrollbar {
  width: 8px;
  background: #bdbdbd;
}
.page__body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #64714F;
  transition: 0.7s background;
}

a {
  color: #64714F;
}

.swal2-styled.swal2-confirm {
  background-color: #64714f !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: 0 0 0 3px rgba(100, 113, 79, 0.5) !important;
}

.header__container {
  padding: 15px;
}

.no-scroll {
  overflow: hidden;
}

.section__title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 54px;
  line-height: 104%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .section__title {
    font-size: 40px;
    margin-bottom: 25px;
  }
}
@media (max-width: 500px) {
  .section__title {
    font-size: 32px;
    margin-bottom: 20px;
  }
}
@media (max-width: 425px) {
  .section__title {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .section__title {
    font-size: 26px;
  }
}
.section__title--dark {
  color: #2c2c2c;
}
.section__title--white {
  color: var(--white-color);
}
.section__title--whites {
  color: var(--white-color);
  margin-bottom: 20px;
}
.section__span {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 54px;
  line-height: 104%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .section__span {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .section__span {
    font-size: 32px;
  }
}
@media (max-width: 425px) {
  .section__span {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .section__span {
    font-size: 26px;
  }
}
.section__span--accent {
  color: #64714f;
}
.section__span--silver {
  color: #898989;
}
.section__line {
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.2);
}
.section__descr {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 123%;
  color: rgba(255, 255, 255, 0.96);
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .section__descr {
    margin-bottom: 20px;
  }
}

.fslightbox-source {
  border-radius: 10px;
}

.fslightbox-toolbar {
  border-radius: 10px;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.is-hidden {
  display: none !important;
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

input,
textarea {
  outline: none;
}

input:active,
textarea:active {
  outline: none;
}

:focus {
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  resize: vertical;
}

textarea {
  resize: horizontal;
}

select {
  appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 13px 0;
  transition: 0.3s background-color ease-in-out;
}
@media (max-width: 968px) {
  .header {
    background-color: var(--white-color);
  }
}
@media (max-width: 968px) {
  .header__container {
    padding: 9px 15px;
  }
}
.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 968px) {
  .header__content {
    height: 30px;
  }
}
.header.scrolled {
  background-color: var(--white-color);
}
.header__right {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 968px) {
  .header__right {
    display: none;
  }
}
@media (max-width: 968px) {
  .header__nav {
    display: none;
  }
}
.header__phone {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: var(--white-color);
}
@media (max-width: 968px) {
  .header__phone {
    color: #64714F;
  }
}
.header__logo svg {
  fill: var(--white-color);
}
@media (max-width: 968px) {
  .header__logo svg {
    fill: #64714f;
    display: flex;
  }
}

.header.scrolled .nav__link {
  color: #64714f;
}

.header.scrolled .nav__item::after {
  background-color: #64714f;
}

.header.scrolled .header__phone {
  color: #64714f;
}

.header.scrolled .header__logo svg {
  fill: #64714f;
}

.hero {
  background-image: url("../img/hero-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  .hero {
    padding-bottom: 50px;
  }
}
.hero__content {
  padding-top: 155px;
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 1100px) {
  .hero__content {
    gap: 20px;
  }
}
@media (max-width: 1024px) {
  .hero__content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
  }
}
.hero__left {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
@media (max-width: 500px) {
  .hero__left {
    gap: 20px;
  }
}
.hero__title {
  max-width: 611px;
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 54px;
  line-height: 104%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.96);
}
@media (max-width: 1024px) {
  .hero__title {
    text-align: center;
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .hero__title {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .hero__title {
    font-size: 32px;
  }
}
@media (max-width: 425px) {
  .hero__title {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .hero__title {
    font-size: 26px;
  }
}
.hero__right {
  max-width: 380px;
}
@media (max-width: 500px) {
  .hero__right {
    height: 250px;
  }
}
@media (max-width: 375px) {
  .hero__right {
    height: 220px;
  }
}
.hero__pic img {
  border-radius: 10px;
}
@media (max-width: 500px) {
  .hero__pic img {
    height: 100%;
  }
}
.hero__advantages {
  display: flex;
  align-items: center;
  gap: 15px;
  backdrop-filter: blur(5.5px);
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 15px 19px;
}
@media (max-width: 768px) {
  .hero__advantages {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .hero__advantages {
    padding: 13px 15px;
  }
}
.hero__advantage {
  display: flex;
  align-items: center;
  gap: 10px;
}
.hero__descr {
  width: 287px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 11.9px;
  line-height: 123%;
  color: rgba(255, 255, 255, 0.96);
}
@media (max-width: 500px) {
  .hero__ico {
    display: none;
  }
}
.hero__info {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 1024px) {
  .hero__info {
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .hero__info {
    flex-direction: column-reverse;
  }
}
.hero__sale {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.3s all ease-in-out;
  animation: coloor 2.5s infinite linear;
}
.hero__name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 92%;
  letter-spacing: -0.05em;
  color: #f7f7f7;
  max-width: 135px;
}

@keyframes coloor {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 50%;
  }
  100% {
    opacity: 0;
  }
}
.about {
  border-radius: 15px;
  background-color: var(--white-color);
  padding: 50px 0;
}
.about__content {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 968px) {
  .about__content {
    flex-direction: column;
    gap: 20px;
  }
}
.about__left {
  max-width: 313px;
  height: 345px;
}
@media (max-width: 500px) {
  .about__left {
    max-width: 100%;
    height: 250px;
  }
}
@media (max-width: 375px) {
  .about__left {
    max-width: 100%;
    height: 220px;
  }
}
.about__pic img {
  border-radius: 10px;
}
@media (max-width: 500px) {
  .about__pic img {
    height: 100%;
  }
}
.about__items {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 10px;
}
@media (max-width: 968px) {
  .about__items {
    justify-content: center;
  }
}
.about__item {
  border: 1px solid rgba(44, 44, 44, 0.4);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
  width: 258px;
}
@media (max-width: 500px) {
  .about__item {
    width: 100%;
  }
}
.about__wrapper {
  display: flex;
  flex-direction: column;
  gap: 22.5px;
  padding: 20px;
}
.about__name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: -0.01em;
  color: #2c2c2c;
}
.about__subname {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 137%;
  letter-spacing: -0.01em;
  color: rgba(44, 44, 44, 0.4);
  max-width: 218px;
}
@media (max-width: 500px) {
  .about__subname {
    max-width: 100%;
  }
}

.jobs {
  padding: 125px 0 140px 0;
  background-image: url("../img/jobs-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 968px) {
  .jobs {
    padding: 50px 0;
  }
}

.gallery {
  padding: 50px 0;
  border-radius: 15px;
  background: #2c2c2c;
}
.gallery__items {
  display: flex;
  align-items: center;
  row-gap: 19px;
  column-gap: 11px;
  padding: 20px 0;
  position: relative;
}
@media (max-width: 1100px) {
  .gallery__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
  }
}
@media (max-width: 968px) {
  .gallery__items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  .gallery__items {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .gallery__item {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .gallery__item--one {
    padding-top: 70px;
  }
}
.gallery__disable {
  height: 341px;
  width: 100%;
}
@media (max-width: 768px) {
  .gallery__disable {
    height: auto;
  }
}
.gallery__text {
  max-width: 220px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  color: rgba(255, 255, 255, 0.96);
}
.gallery__text span {
  color: #858585;
}
.gallery__text--one {
  position: absolute;
  left: 0;
  top: 20px;
}
.gallery__pic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transition: -webkit-transform 0.5s ease-in-out;
  transition: -webkit-transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
  width: 100%;
  height: 341px;
}
.gallery__pic img {
  border-radius: 10px;
  height: 100%;
}
@media (max-width: 768px) {
  .gallery__pic img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .gallery__pic {
    height: 100%;
  }
}
.gallery__pic--container {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .gallery__pic--container {
    width: 100%;
    height: 250px;
  }
}
@media (max-width: 375px) {
  .gallery__pic--container {
    width: 100%;
    height: 220px;
  }
}
.gallery__pic--full {
  height: 341px;
}
@media (max-width: 768px) {
  .gallery__pic--full {
    height: 100%;
  }
}
.gallery__pic--full img {
  height: 162px !important;
}
.gallery__column {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
@media (max-width: 500px) {
  .gallery__f {
    height: 100% !important;
  }
}

.gallery__pic--container:hover .gallery__pic {
  transform: scale(1.25);
}

@media (max-width: 500px) {
  .gallery .gallery__f .gallery__pic--container {
    height: 100%;
  }
}

.contact {
  padding: 100px 0;
  background-image: url("../img/form-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 968px) {
  .contact {
    padding: 50px 0;
  }
}
.contact__place {
  display: flex;
  align-items: center;
  gap: 10px;
  backdrop-filter: blur(5.5px);
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 10px 10px 16px 10px;
}
@media (max-width: 1150px) {
  .contact__place {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1150px) {
  .contact__pic {
    width: 560px;
  }
}
@media (max-width: 768px) {
  .contact__pic {
    width: 100%;
  }
}
.contact__pic img {
  border-radius: 10px;
}
@media (max-width: 1150px) {
  .contact__pic img {
    width: 100%;
  }
}

.questions {
  padding: 50px 0;
  border-radius: 15px 15px 0 0;
  background-color: var(--white-color);
}
@media (max-width: 768px) {
  .questions__accordions {
    width: 100%;
  }
}
@media (max-width: 1150px) {
  .questions__pic {
    width: 570px;
  }
}
@media (max-width: 768px) {
  .questions__pic {
    width: 100%;
    height: 350px;
  }
}
@media (max-width: 500px) {
  .questions__pic {
    height: 250px;
  }
}
@media (max-width: 375px) {
  .questions__pic {
    height: 220px;
  }
}
.questions__pic img {
  border-radius: 10px;
  height: 580px;
}
@media (max-width: 1150px) {
  .questions__pic img {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .questions__pic img {
    height: 100%;
  }
}
.questions__content {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
@media (max-width: 1150px) {
  .questions__content {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.reviews {
  padding: 30px 0;
  border-radius: 0 0 15px 15px;
  background: #fff;
}
.reviews__block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.footer {
  padding: 100px 0;
  background-image: url("../img/footer-img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media (max-width: 968px) {
  .footer {
    padding: 50px 0;
  }
}
@media (max-width: 768px) {
  .footer__yandex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
  }
}
.footer__top {
  display: flex;
  align-items: center;
  gap: 30px;
}
@media (max-width: 768px) {
  .footer__top {
    flex-direction: column;
    gap: 20px;
  }
}
.footer__link {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--white-color);
  transition: 0.3s color ease-in-out;
}
.footer__link:not(:last-child) {
  text-decoration: none;
}
.footer__link:hover {
  color: #e2e2e2;
}
.footer__info {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 768px) {
  .footer__info {
    flex-direction: column;
  }
}
.footer__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 768px) {
  .footer__content {
    gap: 7px;
  }
}
.footer__text {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 768px) {
  .footer__text {
    text-align: center;
  }
}
@media (max-width: 425px) {
  .footer__text {
    font-size: 13px;
  }
}
.footer__social {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.nav__list {
  display: flex;
  align-items: center;
}
.nav__item {
  position: relative;
}
.nav__item:not(:last-child) {
  margin-right: 18px;
}
.nav__item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  background-color: var(--white-color);
  width: 0;
  height: 2px;
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.nav__item:hover::after {
  width: 100%;
}
.nav__link {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--white-color);
}

.btn--call {
  border-radius: 4px;
  background: #64714f;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-transform: uppercase;
  text-align: center;
  color: rgba(255, 255, 255, 0.96);
  padding: 7px 27px;
  transition: 0.3s background ease-in-out;
}
.btn--call:hover {
  background: #424b34;
}
.btn--form {
  background: #64714f;
  border-radius: 8px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: #fff;
  padding: 12px;
  width: 235px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s background ease-in-out;
}
.btn--form:hover {
  background: #424b34;
}
.btn--price {
  background: #64714f;
  border-radius: 8px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: var(--white-color);
  padding: 12px 0;
  width: 273px;
  transition: 0.3s background ease-in-out;
}
@media (max-width: 500px) {
  .btn--price {
    width: 100%;
  }
}
.btn--price:hover {
  background: #424b34;
}
.btn--more {
  margin-top: 40px;
  width: 100%;
  border-radius: 8px;
  background: #64714f;
  padding: 12px 0;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: var(--white-color);
  text-transform: uppercase;
  transition: 0.3s background ease-in-out;
}
.btn--more:hover {
  background: #424b34;
}
.btn--submit {
  border-radius: 8px;
  background: #64714f;
  width: 273px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: var(--white-color);
  transition: 0.3s background ease-in-out;
}
@media (max-width: 1150px) {
  .btn--submit {
    width: 100%;
  }
}
.btn--submit:hover {
  background: #424b34;
}
.btn--view {
  border-radius: 10px;
  width: 272px;
  height: 341px;
  background: #64714f;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: var(--white-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-transition: 0.3s background ease-in-out;
  transition: 0.3s background ease-in-out;
}
@media (max-width: 768px) {
  .btn--view {
    width: 100%;
    height: 250px;
  }
}
@media (max-width: 375px) {
  .btn--view {
    width: 100%;
    height: 220px;
  }
}
.btn--view:hover {
  background: #424b34;
}
.btn--link {
  background: #898989;
  border-radius: 10px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  width: 251px;
  height: 477px;
  -webkit-transition: 0.3s background ease-in-out;
  transition: 0.3s background ease-in-out;
}
.btn--link:hover {
  background: #676767;
}
@media (max-width: 500px) {
  .btn--link {
    width: 100%;
    margin-left: 0;
    height: 50px;
  }
}
.btn--views {
  background: #64714f;
  border-radius: 8px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  -webkit-transition: 0.3s background ease-in-out;
  transition: 0.3s background ease-in-out;
}
.btn--views:hover {
  background: #424b34;
}
.btn--view2 {
  border-radius: 10px;
  width: 272px;
  height: 341px;
  background: var(--white-color);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 138%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  text-align: center;
  color: #2c2c2c;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: middle;
  -webkit-transition: 0.3s background ease-in-out;
  transition: 0.3s background ease-in-out;
}
.btn--view2:hover {
  background: #e2e2e2;
}
@media (max-width: 768px) {
  .btn--view2 {
    width: 100%;
    height: 250px;
  }
}
@media (max-width: 375px) {
  .btn--view2 {
    width: 100%;
    height: 220px;
  }
}

.tabs {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
}
@media (max-width: 768px) {
  .tabs {
    gap: 20px;
  }
}
.tabs__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .tabs__list {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}
@media (max-width: 500px) {
  .tabs__item {
    width: 100%;
  }
}
.tabs__item:not(:last-child) {
  margin-right: 10px;
}
.tabs__btn {
  border: none;
  cursor: pointer;
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 118%;
  text-align: center;
  color: #9b9b9a;
  background: #2c2c2c;
  border-radius: 8px;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 500px) {
  .tabs__btn {
    width: 100%;
  }
}
.tabs__btn:hover {
  color: var(--white-color);
}
.tabs__btn--active {
  color: var(--white-color);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  pointer-events: none;
}
.tabs__content {
  display: none;
}
.tabs__content--active {
  display: block;
}

.content__block {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
@media (max-width: 968px) {
  .content__block {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .content__block {
    margin-bottom: 25px;
  }
}
@media (max-width: 500px) {
  .content__block {
    margin-bottom: 20px;
  }
}
.content__title {
  font-family: var(--second-family);
  font-weight: 700;
  font-size: 54px;
  line-height: 104%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.96);
}
@media (max-width: 768px) {
  .content__title {
    font-size: 40px;
  }
}
@media (max-width: 500px) {
  .content__title {
    font-size: 32px;
  }
}
@media (max-width: 425px) {
  .content__title {
    font-size: 28px;
  }
}
@media (max-width: 375px) {
  .content__title {
    font-size: 26px;
  }
}
.content__descr {
  max-width: 285px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  color: rgba(255, 255, 255, 0.96);
}
@media (max-width: 968px) {
  .content__descr {
    max-width: 100%;
  }
}
.content__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  justify-content: center;
}
@media (max-width: 500px) {
  .content__cards {
    gap: 20px;
  }
}
.content__wrapper {
  padding: 20px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  gap: 11px;
  position: relative;
}
.content__card {
  width: 273px;
  height: 283px;
  border-radius: 10px;
  backdrop-filter: blur(5.5px);
  background: rgba(255, 255, 255, 0.15);
}
@media (max-width: 500px) {
  .content__card {
    width: 100%;
    height: auto;
  }
}
.content__name {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: -0.01em;
  color: var(--white-color);
}
.content__subname {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 137%;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.4);
  max-height: 185px;
  overflow-y: scroll;
  padding-right: 6px;
}
.content__subname::-webkit-scrollbar {
  width: 5px;
}
.content__subname::-webkit-scrollbar-thumb {
  border-radius: 1000px;
  background: var(--white-color);
  transition: 0.7s background;
}
@media (max-width: 375px) {
  .content__subname {
    font-size: 14px;
  }
}
.content__pic {
  max-width: 273px;
  height: 283px;
}
@media (max-width: 500px) {
  .content__pic {
    width: 100%;
    max-width: 100%;
    height: 260px;
  }
}
.content__pic--img {
  height: 100%;
  display: block;
}
.content__pic--img img {
  height: 100%;
  border-radius: 10px;
}

.contact-form {
  width: 560px;
}
@media (max-width: 768px) {
  .contact-form {
    width: 100%;
  }
}
.contact-form__message {
  margin-bottom: 7px;
}
.contact-form__input {
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: var(--white-color);
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  letter-spacing: -0.01em;
  color: #2c2c2c;
  padding: 17px 20px;
}
@media (max-width: 500px) {
  .contact-form__input {
    padding: 14px 20px;
    font-size: 14px;
  }
}
.contact-form__input-wrapper {
  margin-bottom: 10px;
}
.contact-form__input-wrapper_phone {
  position: relative;
}
.contact-form__input::placeholder {
  color: rgba(44, 44, 44, 0.4);
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}
.contact-form__input_phone {
  padding-left: 50px;
}
.contact-form__region {
  position: absolute;
  left: 12px;
  top: 24.5%;
}
.contact-form__policy {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 20px;
  margin-left: 6px;
}
.contact-form__link {
  color: rgba(255, 255, 255, 0.4);
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-checkbox + label {
  display: flex;
  gap: 14px;
  align-items: center;
  user-select: none;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 14px;
  line-height: 169%;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 768px) {
  .custom-checkbox + label {
    font-size: 13px;
  }
}

.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #fff;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23clip0_178_475)'%3e%3cpath d='M7.8589 0.591231L4.24616 4.20363L2.14093 2.0984C1.87659 1.83406 1.44776 1.83406 1.18376 2.0984C0.919423 2.36274 0.919423 2.79123 1.18376 3.05557L3.76758 5.63939C3.89991 5.77172 4.07287 5.83772 4.24616 5.83772C4.41945 5.83772 4.59241 5.77172 4.72475 5.63939L8.81607 1.5484C9.08041 1.28406 9.08041 0.855569 8.81607 0.591231C8.55173 0.326892 8.1229 0.326892 7.8589 0.591231ZM4.99992 -7.37683C-0.598916 -7.37683 -5.15393 -2.82182 -5.15393 2.77702C-5.15393 8.37585 -0.598916 12.9309 4.99992 12.9309C10.5987 12.9309 15.1538 8.37585 15.1538 2.77702C15.1538 -2.82182 10.5987 -7.37683 4.99992 -7.37683ZM4.99992 11.577C0.14773 11.577 -3.80008 7.62954 -3.80008 2.77702C-3.80008 -2.07551 0.14773 -6.02298 4.99992 -6.02298C9.8521 -6.02298 13.7999 -2.07551 13.7999 2.77702C13.7999 7.62954 9.8521 11.577 4.99992 11.577Z' fill='white' /%3e%3c/g%3e%3cdefs%3e%3cclipPath id='clip0_178_475'%3e%3crect width='8.8' height='6.09231' fill='white' transform='translate(0.599976 0.0693359)' /%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");
}

.accordion {
  width: 570px;
}
@media (max-width: 768px) {
  .accordion {
    width: 100%;
  }
}
.accordion__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 570px;
}
@media (max-width: 768px) {
  .accordion__list {
    width: 100%;
  }
}
.accordion__control {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  padding: 16px;
  background: #F8F8F8;
  border-radius: 10px;
  border: none;
}
@media (max-width: 500px) {
  .accordion__control {
    padding: 14px;
  }
}
@media (max-width: 410px) {
  .accordion__control {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-start;
  }
}
.accordion__control::-webkit-details-marker {
  display: none;
}
.accordion__title {
  font-family: var(--third-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
  color: #2c2c2c;
}
@media (max-width: 500px) {
  .accordion__title {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .accordion__title {
    text-align: center;
  }
}
.accordion__icon {
  position: relative;
  display: inline-block;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  background-color: #64714f;
  transition: 0.3s background-color ease-in-out;
}
.accordion__icon::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 2px;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.accordion__icon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
}
.accordion__content {
  margin-top: -12px;
  padding-top: 20px;
  border: none;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  will-change: max-height;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  background: #F8F8F8;
  border-radius: 0 0 10px 10px;
}
.accordion__descr {
  max-width: 464px;
  font-family: var(--third-family);
  font-weight: 500;
  font-size: 13px;
  line-height: 146%;
  color: rgba(44, 44, 44, 0.4);
}
.accordion__icon {
  transition: transform 0.3s ease-out;
}
.open .accordion__icon {
  transform: rotate(45deg);
  background-color: #9cae7f;
}
.open .accordion__content {
  opacity: 1;
  padding: 20px 10px 23px 20px;
}

.social {
  display: flex;
  align-items: center;
  gap: 11px;
}
.social__item {
  position: relative;
  height: 22px;
  display: flex;
  align-items: center;
}
.social__item:not(:last-child) {
  padding-right: 11px;
}
.social__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 22px;
  width: 2px;
  background-color: #a5a4a2;
}

.mobile {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (min-width: 968px) {
  .mobile {
    display: none;
  }
}

.mobile div {
  display: block;
  position: sticky;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999999;
  cursor: pointer;
}

.mobile div span {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 4px;
  background-color: #64714F;
  border-radius: 13px;
  transition-duration: 0.4s;
}

.mobile div span:nth-child(1) {
  top: 14px;
}

.mobile div span:nth-child(2) {
  top: 23px;
}

.mobile div span:nth-child(3) {
  bottom: 14px;
}

.mobile div.active span:nth-child(1) {
  transform: translate(-15px, 9px) rotate(-45deg);
}

.mobile div.active span:nth-child(2) {
  transition-duration: 0s;
  opacity: 0;
}

.mobile div.active span:nth-child(3) {
  transform: translate(-15px, -9px) rotate(45deg);
}

.mobile nav {
  display: block;
  position: fixed;
  bottom: 16px;
  right: 16px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 9999990;
  transition-duration: 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.mobile nav.open {
  background-color: #9CAE7F;
  width: 100%;
  height: 100%;
  right: 0px;
  bottom: 0px;
  border-radius: 0;
}

.mobile nav ul {
  display: none;
}

.mobile nav ul.show {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  opacity: 0;
  animation-name: fadein;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.mobile ul li:not(:last-child) {
  margin-bottom: 22px;
}

.mobile ul li a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--white-color);
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.policy {
  padding-top: 98px;
  padding-bottom: 30px;
  font-family: var(--font-family);
  color: #fff;
}
.policy__title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
.policy__subtitle {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
.policy__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 20px;
}
.policy__text a {
  color: #64714f;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(86, 86, 86, 0.7215686275);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  /* Видимое состояние модального окна */
  /* Закрытие окна */
}
.modal__content {
  background-color: white;
  padding: 60px 52px 60px 42px;
  width: 90%;
  max-width: 1130px;
  position: relative;
  transform: translateY(-50px);
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  overflow-y: scroll;
  max-height: 100vh;
  border-radius: 10px;
}
.modal__content::-webkit-scrollbar {
  width: 8px;
  background: #bdbdbd;
}
.modal__content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #64714F;
  transition: 0.7s background;
}
@media (max-width: 768px) {
  .modal__content {
    width: 95%;
  }
}
.modal__line {
  border-radius: 100px;
  max-width: 1120px;
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}
.modal__close {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 13px;
  font-size: 33px;
  cursor: pointer;
}
.modal.show {
  display: flex;
  opacity: 1;
}
.modal.show .modal__content {
  transform: translateY(0);
  opacity: 1;
}
.modal.hide {
  opacity: 0;
}
.modal.hide .modal__content {
  transform: translateY(-50px);
  opacity: 0;
}
.modal__items {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
  -webkit-column-gap: 9px;
  -moz-column-gap: 9px;
  column-gap: 9px;
}
@media (max-width: 500px) {
  .modal__items {
    row-gap: 20px;
  }
}
.modal__item {
  max-width: 249px;
}
@media (max-width: 500px) {
  .modal__item {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 500px) {
  .modal__link {
    width: 100%;
  }
}
.modal__pic img {
  border-radius: 11px;
  height: 340px;
}
@media (max-width: 500px) {
  .modal__pic img {
    width: 100%;
    max-height: 250px;
  }
}
@media (max-width: 425px) {
  .modal__pic img {
    max-height: 200px;
  }
}
.modal__texts {
  margin-top: 20px;
  margin-bottom: 30px;
}
.modal__name {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  color: #2c2c2c;
}
.modal__subname {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 13px;
  line-height: 123%;
  color: #898989;
}

.no-scroll {
  overflow: hidden;
}