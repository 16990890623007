.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #565656b8;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;

  &__content {
    background-color: white;
    padding: 60px 52px 60px 42px;
    width: 90%;
    max-width: 1130px;
    position: relative;
    transform: translateY(-50px);
    transition: transform 0.5s ease,
      opacity 0.5s ease;
    opacity: 0;
    overflow-y: scroll;
    max-height: 100vh;
    border-radius: 10px;

    &::-webkit-scrollbar {
      width: 8px;
      background: #bdbdbd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #64714F;
      transition: .7s background;
    }

    @media (max-width: 768px) {
      & {
        width: 95%;
      }
    }
  }

  &__line {
    border-radius: 100px;
    max-width: 1120px;
    width: 100%;
    height: 2px;
    background-color: rgba(#000, 0.2);
  }

  &__close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
    font-size: 33px;
    cursor: pointer;
  }

  /* Видимое состояние модального окна */
  &.show {
    display: flex;
    opacity: 1;
  }

  &.show &__content {
    transform: translateY(0);
    opacity: 1;
  }

  /* Закрытие окна */
  &.hide {
    opacity: 0;
  }

  &.hide &__content {
    transform: translateY(-50px);
    opacity: 0;
  }

  &__items {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    row-gap: 40px;
    -webkit-column-gap: 9px;
    -moz-column-gap: 9px;
    column-gap: 9px;
  }

  @media (max-width: 500px) {
    &__items {
      row-gap: 20px;
    }
  }

  &__item {
    max-width: 249px;
  }

  @media (max-width: 500px) {
    &__item {
      max-width: 100%;
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    &__link {
      width: 100%;
    }
  }

  &__pic img {
    border-radius: 11px;
    height: 340px;
  }

  @media (max-width: 500px) {
    &__pic img {
      width: 100%;
      max-height: 250px;
    }
  }

  @media (max-width: 425px) {
    &__pic img {
      max-height: 200px;
    }
  }

  &__texts {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    color: #2c2c2c;
  }

  &__subname {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    color: #898989;
  }
}

.no-scroll {
  overflow: hidden;
}
