.hero {
  background-image: url('../img/hero-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 100px;

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  &__content {
    padding-top: 155px;
    display: flex;
    align-items: center;
    gap: 40px;

    @media (max-width: 1100px) {
      gap: 20px;
    }

    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-top: 100px;
    }
  }

  &__left {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 500px) {
      gap: 20px;
    }
  }

  &__title {
    max-width: 611px;
    font-family: var(--second-family);
    font-weight: 700;
    font-size: 54px;
    line-height: 104%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.96);

    @media (max-width: 1024px) {
      text-align: center;
      max-width: 100%;
    }

    @media (max-width: 768px) {
      font-size: 40px;
    }

    @media (max-width: 500px) {
      font-size: 32px;
    }

    @media (max-width: 425px) {
      font-size: 28px;
    }

    @media (max-width: 375px) {
      font-size: 26px;
    }
  }

  &__right {
    max-width: 380px;

    @media (max-width: 500px) {
      height: 250px;
    }

    @media (max-width: 375px) {
      height: 220px;
    }
  }

  &__pic {
    img {
      border-radius: 10px;

      @media (max-width: 500px) {
        height: 100%;
      }
    }
  }

  &__advantages {
    display: flex;
    align-items: center;
    gap: 15px;
    backdrop-filter: blur(5.5px);
    background: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    padding: 15px 19px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 500px) {
      padding: 13px 15px;
    }
  }

  &__advantage {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__descr {
    width: 287px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 11.9px;
    line-height: 123%;
    color: rgba(255, 255, 255, 0.96);
  }

  &__ico {
    @media (max-width: 500px) {
      display: none;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 1024px) {
      align-items: center;
      justify-content: center;
    }

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }
  }

  &__sale {
    display: flex;
    align-items: center;
    gap: 10px;
    transition: .3s all ease-in-out;
    animation: coloor 2.5s infinite linear;
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 92%;
    letter-spacing: -0.05em;
    color: #f7f7f7;
    max-width: 135px;
  }
}

@keyframes coloor {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 50%;
  }

  100% {
    opacity: 0;
  }
}
