.social {
  display: flex;
  align-items: center;
  gap: 11px;

  &__item {
    position: relative;
    height: 22px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-right: 11px;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 22px;
        width: 2px;
        background-color: #a5a4a2;
      }
    }
  }
}
