.footer {
  padding: 100px 0;
  background-image: url('../img/footer-img.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 968px) {
    padding: 50px 0;
  }

  &__yandex {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 157%;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--white-color);
    transition: .3s color ease-in-out;

    &:not(:last-child) {
      text-decoration: none;
    }

    &:hover {
      color: #e2e2e2;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 768px) {
      gap: 7px;
    }
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.01em;
    color: rgba(255, 255, 255, 0.5);

    @media (max-width: 768px) {
      text-align: center;
    }

    @media (max-width: 425px) {
      font-size: 13px;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
}
