.contact {
  padding: 100px 0;
  background-image: url('../img/form-bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 968px) {
    padding: 50px 0;
  }

  &__place {
    display: flex;
    align-items: center;
    gap: 10px;
    backdrop-filter: blur(5.5px);
    background: rgba(#fff, 0.15);
    border-radius: 10px;
    padding: 10px 10px 16px 10px;

    @media (max-width: 1150px) {
      flex-direction: column-reverse;
    }
  }

  &__pic {
    @media (max-width: 1150px) {
      width: 560px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }

    img {
      border-radius: 10px;

      @media (max-width: 1150px) {
        width: 100%;
      }
    }
  }
}
