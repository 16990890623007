.about {
  border-radius: 15px;
  background-color: var(--white-color);
  padding: 50px 0;

  &__content {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 968px) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__left {
    max-width: 313px;
    height: 345px;

    @media (max-width: 500px) {
      max-width: 100%;
      height: 250px;
    }

    @media (max-width: 375px) {
      max-width: 100%;
      height: 220px;
    }
  }

  &__pic {
    img {
      border-radius: 10px;

      @media (max-width: 500px) {
        height: 100%;
      }
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 10px;

    @media (max-width: 968px) {
      justify-content: center;
    }
  }

  &__item {
    border: 1px solid #2c2c2c66;
    border-radius: 10px;
    background: rgba(#fff, 0.4);
    width: 258px;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 22.5px;
    padding: 20px;
  }

  &__name {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: #2c2c2c;
  }

  &__subname {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 137%;
    letter-spacing: -0.01em;
    color: rgba(#2c2c2c, 0.4);
    max-width: 218px;

    @media (max-width: 500px) {
      max-width: 100%;
    }
  }
}
