.questions {
  padding: 50px 0;
  border-radius: 15px 15px 0 0;
  background-color: var(--white-color);

  &__accordions {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__pic {
    @media (max-width: 1150px) {
      width: 570px;
    }

    @media (max-width: 768px) {
      width: 100%;
      height: 350px;
    }

    @media (max-width: 500px) {
      height: 250px;
    }

    @media (max-width: 375px) {
      height: 220px;
    }

    img {
      border-radius: 10px;
      height: 580px;

      @media (max-width: 1150px) {
        width: 100%;
      }

      @media (max-width: 768px) {
        height: 100%;
      }
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media (max-width: 1150px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
}
