.gallery {
  padding: 50px 0;
  border-radius: 15px;
  background: #2c2c2c;

  &__items {
    display: flex;
    align-items: center;
    row-gap: 19px;
    column-gap: 11px;
    padding: 20px 0;
    position: relative;

    @media (max-width: 1100px) {
      & {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        align-items: center;
      }
    }

    @media (max-width: 968px) {
      & {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media (max-width: 768px) {
      & {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__item {
    @media (max-width: 768px) {
      & {
        width: 100%;
      }
    }

    @media (max-width: 768px) {
      &--one {
        padding-top: 70px;
      }
    }
  }

  &__disable {
    height: 341px;
    width: 100%;
  }

  @media (max-width: 768px) {
    &__disable {
      height: auto;
    }
  }

  &__text {
    max-width: 220px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 123%;
    color: rgba(255, 255, 255, 0.96);

    & span {
      color: #858585;
    }

    &--one {
      position: absolute;
      left: 0;
      top: 20px;
    }
  }

  &__pic {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    width: 100%;
    height: 341px;

    & img {
      border-radius: 10px;
      height: 100%;
    }

    @media (max-width: 768px) {
      & img {
        width: 100%;
        height: 100%;
      }
    }

    @media (max-width: 768px) {
      & {
        height: 100%;
      }
    }

    &--container {
      overflow: hidden;
      position: relative;
      border-radius: 10px;

      @media (max-width: 768px) {
        & {
          width: 100%;
          height: 250px;
        }
      }

      @media (max-width: 375px) {
        & {
          width: 100%;
          height: 220px;
        }
      }
    }

    &--full {
      height: 341px;

      @media (max-width: 768px) {
        & {
          height: 100%;
        }
      }

      & img {
        height: 162px !important;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  &__f {
    @media (max-width: 500px) {
      height: 100% !important;
    }
  }
}



.gallery__pic--container:hover .gallery__pic {
  transform: scale(1.25);
}

.gallery .gallery__f .gallery__pic--container {
  @media (max-width: 500px) {
    height: 100%;
  }
}
