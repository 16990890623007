.jobs {
  padding: 125px 0 140px 0;
  background-image: url('../img/jobs-img.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media (max-width: 968px) {
    padding: 50px 0;
  }
}
