.policy {
  padding-top: calc(68px + 30px);
  padding-bottom: 30px;
  font-family: var(--font-family);
  color: #fff;

  &__title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 10px;
  }

  &__text {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    margin-left: 20px;

    a {
      color: #64714f;
    }
  }


}
