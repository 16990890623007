.nav {

  &__list {
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 18px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      background-color: var(--white-color);
      width: 0;
      height: 2px;
      transition: .3s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &__link {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--white-color);
  }
}
