.accordion {
  width: 570px;

  @media (max-width: 768px) {
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 570px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__control {
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 16px;
    background: #F8F8F8;
    border-radius: 10px;
    border: none;

    @media (max-width: 500px) {
      padding: 14px;
    }

    @media (max-width: 410px) {
      flex-direction: column-reverse;
      gap: 10px;
      align-items: flex-start;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &__title {
    font-family: var(--third-family);
    font-weight: 600;
    font-size: 16px;
    line-height: 137%;
    color: #2c2c2c;

    @media (max-width: 500px) {
      font-size: 14px;
    }

    @media (max-width: 425px) {
      text-align: center;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    width: 31px;
    height: 31px;
    border-radius: 100%;
    background-color: #64714f;
    transition: .3s background-color ease-in-out;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 50%;
      height: 2px;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 50%;
      background-color: #fff;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    margin-top: -12px;
    padding-top: 20px;
    border: none;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    will-change: max-height;
    transition: all 0.3s ease-out;
    box-sizing: border-box;
    background: #F8F8F8;
    border-radius: 0 0 10px 10px;
  }

  &__descr {
    max-width: 464px;
    font-family: var(--third-family);
    font-weight: 500;
    font-size: 13px;
    line-height: 146%;
    color: rgba(44, 44, 44, 0.4);
  }

  &__icon {
    transition: transform 0.3s ease-out;
  }

  .open &__icon {
    transform: rotate(45deg);
    background-color: #9cae7f;
  }

  .open &__content {
    opacity: 1;
    padding: 20px 10px 23px 20px;
  }
}
